/* eslint-disable react/prop-types */
import React from 'react'
import { graphql } from 'gatsby'
import Careers from 'routes/about/careers'

// Meta Component
import Meta from 'components/Meta/Meta'

// Careers Route
export default Careers

// Gatsby SEO Head
export function Head({ data }) {
  return <Meta tags={data.page.seoMetaTags.tags} />
}

export const query = graphql`
  query CareersQuery {
    page: datoCmsPage(slug: { eq: "careers" }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    careers: allDatoCmsCareer(filter: { isLive: { eq: true } }) {
      edges {
        node {
          slug
          title
          contentNode {
            childMarkdownRemark {
              excerpt(pruneLength: 100)
            }
          }
          fontawesomeIconName
        }
      }
    }
  }
`
