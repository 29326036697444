import React from 'react'
import { Container, Hero, Columns } from 'react-bulma-components'

// Components
import Separator from 'components/separator'

function CareerHero() {
  return (
    <Hero className="is-small">
      <Hero.Body>
        <Container className="container content">
          <Columns className="is-tablet is-vcentered">
            <Columns.Column className="is-offset-1 is-5 is-vcentered">
              <h1 className="title">Join the rebellion.</h1>
              <p className="is-size-4">
                Pocketworks tries to do things a little differently; to gain
                long term clients with meaningful work where the outcomes are
                the most important thing.
              </p>
              <p className="is-size-4">
                You won't be dragged into long hours, rushing stuff out the door
                and taking every project that comes our way.{' '}
              </p>
            </Columns.Column>
            <Columns.Column className="is-offset-1 is-4">
              <img
                src="https://www.datocms-assets.com/20955/1580922055-it-s-an-app.png?fm=png&compress=auto&w=550"
                alt="App"
              />

              <Columns className="is-vcentered is-mobile">
                <Columns.Column className="is-3">
                  <img
                    src="https://vangogh.teespring.com/v3/image/bGzKQDHHs3ANNdizjZQ2QHv2kfI/800/800.jpg"
                    alt=""
                    className="is-rounded has-shadow"
                  />
                </Columns.Column>
                <Columns.Column>
                  <span className="is-size-6">
                    Like the admiral?{' '}
                    <a
                      className="has-text-primary"
                      href="https://www.pocketworks.shop/listing/it-s-an-app-mug?product=1566"
                    >
                      Get the mug!
                    </a>
                  </span>
                </Columns.Column>
              </Columns>
            </Columns.Column>
          </Columns>
          <Separator rtl />
        </Container>
      </Hero.Body>
    </Hero>
  )
}

export default CareerHero
