import PropTypes from 'prop-types'
import React from 'react'

// components
import Layout from 'components/layout'
import SiteNav from 'components/navbar'

// Page Components
import SiteLinks from './components/site-links'
import CareerHero from './components/career-hero'
import Hiring from './components/hiring'
import LifeAtPocketeers from './components/life-at-pocketworks'

// function Careers({ data: { page, careers, instagram } }) {
function Careers({ data: { careers } }) {
  return (
    <Layout>
      <SiteNav fullLogo />
      <SiteLinks activePage="join" />
      <CareerHero />
      <Hiring careers={careers} />
      <LifeAtPocketeers />
    </Layout>
  )
}

Careers.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Careers
