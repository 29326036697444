import React from 'react'
import { Section, Container, Columns } from 'react-bulma-components'

function LifeAtPocketeers() {
  return (
    <Section className="is-hidden">
      <Container className="container content">
        <h2 className="title has-text-centered">
          <span className="has-text-success">Life</span> at Pocketworks
        </h2>
        <p>&nbsp;</p>
        <Columns className="is-mobile">
          <Columns.Column className="is-offset-1 is-10">
            <Columns className="is-mobile">
              {/* {instagram.edges.map(({ node: instaPost }) => (
              <Columns.Column className="is-one-quarter-desktop is-half-mobile is-one-third-tablet">
                <a
                  href={`https://www.instagram.com/p/${instaPost.id}/`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Img
                    className="instagram-photo"
                    fluid={instaPost.localFile.childImageSharp.fluid}
                    imgStyle={{ aspectRatio: 1 }}
                    title={instaPost.caption}
                  />
                </a>
              </Columns.Column>
            ))} */}
              <Columns.Column className="is-mobile mx-auto">
                <h1>
                  <center>Instagram Component Goes Here</center>
                </h1>
              </Columns.Column>
            </Columns>
            <div className="has-text-centered">
              <a href="https://www.instagram.com/pocketworks/">
                <span className="icon is-medium is-link">
                  <i className="fab fa-instagram" />
                </span>
                View more photos on Instagram
              </a>
            </div>
          </Columns.Column>
        </Columns>
      </Container>
    </Section>
  )
}

export default LifeAtPocketeers
