import PropTypes from 'prop-types'
import React from 'react'
import { Card, Section, Container, Columns } from 'react-bulma-components'
import ServicesBlock from 'components/services-block'

// Components
import ArrowLink from 'components/link-with-arrow'
import Separator from 'components/separator'

function Hiring({ careers }) {
  return (
    <>
      <Section>
        <Container className="content">
          <Columns className="is-centered">
            <Columns.Column className="is-12 has-text-centered">
              <h2
                className={
                  careers.edges.length === 0 ? 'is-hidden title' : 'title'
                }
              >
                We're hiring...
              </h2>
              <h2
                className={
                  careers.edges.length > 0 ? 'is-hidden title' : 'title'
                }
              >
                Drat, we're{' '}
                <span className="has-text-danger">a full house</span> right
                now...
              </h2>
              <p
                className={
                  careers.edges.length > 0 ? 'is-hidden title' : 'titles'
                }
              >
                <span class="is-size-3">
                  But don't run off, something could open up very soon.
                </span>{' '}
                <br />
                <br />
                So, if you're a user researcher, product manager, sales guru,
                digital product designer or <br /> developer, please{' '}
                <strong>
                  <a href="mailto:careers@pocketworks.co.uk">
                    send Jenny a quick "hello"
                  </a>
                </strong>{' '}
                and she'll make sure we get in touch when a position opens up.
                <br />
                <br />
                Thanks,
                <br />
                <br />
                The Pocketworks Team
              </p>
              <br />
              <Columns className="is-multiline is-centered has-text-left">
                {careers.edges.map(({ node: career }) => (
                  <Columns.Column
                    className="is-5-desktop is-6-tablet is-12-mobile"
                    key={career.id}
                  >
                    <Card className=" is-equal-height">
                      <Card.Content>
                        <div className="icon is-large is-rounded">
                          <i
                            className={`${career.fontawesomeIconName} fa-lg`}
                          />
                        </div>
                        <h3>{career.title}</h3>
                        {career.contentNode.childMarkdownRemark.excerpt}
                      </Card.Content>
                      <Card.Footer>
                        <ArrowLink
                          to={`/careers/${career.slug}`}
                          className="card-footer-item"
                        >
                          View &amp; Apply
                        </ArrowLink>
                      </Card.Footer>
                    </Card>
                  </Columns.Column>
                ))}
              </Columns>
            </Columns.Column>
          </Columns>
        </Container>
      </Section>
      <Separator />
      <Section>
        <Container>
          <ServicesBlock
            title={<span>What's it like working with us?</span>}
            subtitle="Learn about the culture and benefits at Pocketworks."
            items={[
              {
                title: '💪🏽 Independence',
                text: 'Enjoy decision-making power with direct access to clients and the ability to contribute in all phases of product development.',
              },
              {
                title: '⛱ 2x Yearly Retreats',
                text: 'Get flown out to a friendly four-day retreat twice a year somewhere in Europe. Enjoy learning, teamwork and bad karaoke!',
              },
              {
                title: '🌍 Remote First',
                text: 'Join a remote-first company with no pressure to come into the office. Work from anywhere or travel whilst working.',
              },
              {
                title: '📈 Cofounders Scheme',
                text: "Many of us have side-hustles and seek financial freedom. Let's make that a win-win. Get up to £50,000 investment to help your ideas fly.",
                url: '',
              },
              {
                title: '🌱 Outcomes Matter',
                text: 'We hate feature factories. Work alongside competent, passionate people building products that have a positive impact.',
              },
              {
                title: '🤝 Flat Heirarchy',
                text: 'Nobody is at the bottom of the food chain. Everyone has an equal opportunity to contribute and take ownership.',
              },
              {
                title: '☕️ Optional Office',
                text: 'Work from the Leeds HQ whenever you fancy a change of scenery. It has a nice vibe, cafe, bar and roof terrace.',
                url: '',
              },
              {
                title: '📷 Flexi Fridays',
                text: "If you're on top of your work, use Friday afternoons for learning, research, side-projects or hobbies.",
                url: '',
              },
              {
                title: '💰 Annual Bonus',
                text: "If the company is doing well and you're doing your bit, we share the wealth and you'll receive a bonus up to 10% of salary.",
                url: '',
              },
              {
                title: '👩🏽‍🎓 Learning Fund',
                text: 'You have £1,000 allocated for learning every year, give or take. Use it to upskill in any area you want, even personal development.',
                url: '',
              },
              {
                title: '💷 PocketMoney',
                text: "There's a pot of tech funds just for you. Use it for work-related purchases. You can add more to it by completing quests.",
                url: '',
              },
              {
                title: '🙏🏼 Referral Bonus',
                text: "We don't use recruiters, so if you introduce us to someone that ends up joining Pocketworks, we'll thank you with a bonus, typically £1,000 - £2,000.",
                url: '',
              },
              {
                title: '🪴 Sensible Career Growth',
                text: "You don't have to be a manager to earn the big bucks. We reward you for excelling at what you do best, and help you grow your skills along the way.",
                url: '',
              },
            ]}
          />
        </Container>
      </Section>
    </>
  )
}
Hiring.propTypes = {
  careers: PropTypes.object,
}
export default Hiring
